import { template as template_46a935145fe04a80aac65809901ca5d6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_46a935145fe04a80aac65809901ca5d6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
