import { template as template_32e41a3e70e14c34b7c32e30d58d248c } from "@ember/template-compiler";
const SidebarSectionMessage = template_32e41a3e70e14c34b7c32e30d58d248c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
