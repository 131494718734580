import { template as template_12374e517a124d3fbd1f7132972ae736 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_12374e517a124d3fbd1f7132972ae736(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
